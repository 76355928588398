import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthModule } from "./shared/auth/authModule";
import getAuthProvider from "./shared/auth/authProvider";
import { getConfig } from "./shared/config/appConfig";
import { IAuthContext } from "./types";
import { FakeAuthModule } from "./__mocks__/msw/fakeAuthModule";

export const ConfigContext = React.createContext({} as any);
export const AuthProviderContext = React.createContext({} as IAuthContext);

const init = async () => {
  const config = await getConfig();
  console.log("config: ", config);

  const version = document.querySelector(
    "meta[name='build-version']"
  ) as HTMLMetaElement;
  console.log("version: ", version.content);

  const authModule: AuthModule =
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_HTTP_MOCK_ENABLED === "TRUE"
      ? ((new FakeAuthModule() as unknown) as AuthModule)
      : new AuthModule(config);

  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_HTTP_MOCK_ENABLED === "TRUE"
  ) {
    const { getWorker } = require("./__mocks__/msw/browser");
    getWorker(config).start({ onUnhandledRequest: "bypass" });
  }

  ReactDOM.render(
    <React.StrictMode>
      <ConfigContext.Provider value={config}>
        <AuthProviderContext.Provider
          value={{ authProvider: getAuthProvider(authModule), authModule }}
        >
          <App />
        </AuthProviderContext.Provider>
      </ConfigContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
