import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  ACS_APP_PATH,
  AUFLS_APP_PATH,
  DANDE_APP_PATH,
  NZGB_APP_PATH,
  OM_APP_PATH,
  POCP_APP_PATH,
} from "../constants";
import { IMenuLink } from "../types";
import {
  AcsIcon,
  AuflsIcon,
  DandeIcon,
  NzgbIcon,
  OmIcon,
  PocpIcon,
} from "./AppLogos";

export const backToPortal: IMenuLink = {
  name: "Back To Portal",
  href: "/",
  text: "Back To Portal",
  icon: ArrowBackIcon,
  type: "link",
  options: { label: "Back To Portal", menuDividerAbove: true },
};

export const portalLinkAUFLS: IMenuLink = {
  name: "AUFLS",
  type: "link",
  href: AUFLS_APP_PATH,
  text: "AUFLS",
  permission: /ER\..*/,
  icon: AuflsIcon,
  options: {
    menuDividerBelow: true,
  },
};

export const portalLinkACS: IMenuLink = {
  name: "ACS",
  type: "link",
  href: ACS_APP_PATH,
  text: "ACS",
  permission: /ACS\..*/,
  icon: AcsIcon,
  options: {
    menuDividerBelow: true,
  },
};

export const portalLinkPOCP: IMenuLink = {
  name: "POCP",
  type: "link",
  href: POCP_APP_PATH,
  text: "POCP",
  icon: PocpIcon,
  options: {
    menuDividerBelow: true,
  },
};

export const portalLinkNZGB: IMenuLink = {
  name: "NZGB",
  type: "link",
  href: NZGB_APP_PATH,
  text: "NZGB",
  icon: NzgbIcon,
  options: {
    menuDividerBelow: true,
  },
};

export const portalLinkDANDE: IMenuLink = {
  name: "SO REGISTER",
  type: "link",
  href: DANDE_APP_PATH,
  text: "SO Register",
  icon: DandeIcon,
  options: {
    menuDividerBelow: true,
  },
};

export const portalLinkOM: IMenuLink = {
  name: "OM",
  type: "link",
  href: OM_APP_PATH,
  text: "Outage Management",
  permission: /OM\..*/,
  icon: OmIcon,
  options: {
    menuDividerBelow: true,
  },
};

export function getPortalLinks(config) {
  return [
    portalLinkAUFLS,
    portalLinkACS,
    portalLinkPOCP,
    portalLinkNZGB,
  ].concat(config.REACT_APP_DANDE_ENABLED === "TRUE" ? [portalLinkDANDE] : []);
}
